<!-- Header -->
<header class="bg-white p-4 flex justify-between items-center">
	<!-- Logo and Company Name -->
	<div class="flex items-center space-x-3 ml-4 md:ml-8">
		<img
			src="https://ik.imagekit.io/wrfy4pvbj/datadonkee/logo.png?f=webp"
			alt="Data Donkee Logo"
			class="h-10 w-10"
			width="48"
			height="48"
		/>
		<span class="text-2xl font-heading font-bold text-primary-600">Data Donkee</span>
	</div>
</header>
