<script>
	import { LinkedinBrand, XTwitterBrand } from 'svelte-awesome-icons';
</script>

<!-- Footer -->
<footer class="bg-dark text-white py-12">
	<div class="container mx-auto px-6 md:px-12">
		<div class="flex flex-col md:flex-row justify-between items-start space-y-12 md:space-y-0">
			<!-- Logo and Company Info -->
			<div class="md:w-1/3 text-center md:text-left">
				<div class="flex items-center justify-center md:justify-start mb-4 space-x-3">
					<img
						src="https://ik.imagekit.io/wrfy4pvbj/datadonkee/logo.png?f=webp"
						alt="Data Donkee Logo"
						class="h-12 w-12"
						width="48"
						height="48"
					/>
					<span class="text-2xl font-heading font-bold">Data Donkee</span>
				</div>
				<p class="text-gray-400 mb-8 md:mb-6">
					Simplifying web data extraction with AI-driven solutions. Empower your business with
					instant access to the data you need.
				</p>
			</div>
		</div>
		<!-- Bottom Section with Social Links -->
		<div class="mt-12 flex flex-col md:flex-row justify-between items-center">
			<p class="text-gray-300">© 2024 Data Donkee. All Rights Reserved.</p>
			<!-- Social Media Links -->
			<div class="flex space-x-6 mt-6 md:mt-0">
				<a
					href="https://www.linkedin.com/company/data-donkee"
					class="text-gray-400 hover:text-white"
				>
					<span class="sr-only">Visit Data Donkee on LinkedIn</span>
					<LinkedinBrand size="30" aria-hidden="true" />
					<span class="sr-only">LinkedIn</span>
				</a>
				<a
					href="https://x.com/DataDonkeeHQ"
					class="text-gray-400 hover:text-white"
					aria-label="Visit Data Donkee on X (formerly Twitter)"
				>
					<span class="sr-only">Visit Data Donkee on X (formerly Twitter)</span>
					<XTwitterBrand size="30" aria-hidden="true" />
					<span class="sr-only">X (Twitter)</span>
				</a>
			</div>
		</div>
	</div>
</footer>
