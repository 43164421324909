<script>
	import { browser } from '$app/environment';
	import { page } from '$app/stores';
	import posthog from 'posthog-js';
	import { onMount } from 'svelte';

	if (browser) {
		posthog.init('phc_uDAK1OZu0ulHk7FKj7o9gj2jOwkzbi74iONSfwR6vaW', {
			api_host: 'https://eu.i.posthog.com',
			person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
			capture_pageview: false,
			capture_pageleave: false
		});
	}

	let currentPath = '';

	onMount(async () => {
		if (typeof window !== 'undefined') {
			const unsubscribePage = page.subscribe(($page) => {
				if (currentPath && currentPath !== $page.url.pathname) {
					// console.log('leaving')
					posthog.capture('$pageleave');
				}
				// console.log('entering')
				currentPath = $page.url.pathname;
				posthog.capture('$pageview');
			});

			const handleBeforeUnload = () => {
				posthog.capture('$pageleave');
			};
			window.addEventListener('beforeunload', handleBeforeUnload);

			return () => {
				unsubscribePage();
				window.removeEventListener('beforeunload', handleBeforeUnload);
			};
		}
	});
</script>
